import React from 'react'
import bg from '../../assets/images/initial_bg.svg'
import Icon from '../../assets/images/logo_icon.png'

const InitialPage = () => {
  return (
    <div style={{backgroundImage:`url(${bg})`, height:"100vh"}} className='d-flex justify-content-center align-items-center'>
            <img src={Icon}  alt='icon' width="40%"/>
    </div>
  )
}

export default InitialPage