import React,  { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { HashRouter } from "react-router-dom";
import InitialLaoding from './screens/login/InitialPage.jsx'
const App = React.lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import("./App")), 1000);
  });
});



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <HashRouter>
    <Suspense fallback={<InitialLaoding />}>
      <App />
    </Suspense>
  </HashRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
